import React from "react"
import "../css/ImgInvitatie.css"
import noi from "../images/n.jpeg"
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { WhatsApp } from "@mui/icons-material";
import data from "../Data"
import PhoneIcon from '@mui/icons-material/Phone';

const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" >
            <h1>Invitație</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4">
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">
                        <div className="text mb-5">
                        <h5>
      Pentru că cele mai frumoase momente se petrec <br/>
alături de oamenii dragi sufletului <br/>
NOI,
</h5>
      <h4>Grigore și Diana</h4>                
<h5>
 Alături de mult stimați nași:   
</h5>
<h4>
 Cosmin și Larisa   
</h4>
<h5>
 Avem deosebita plăcere să vă invităm cu drag, <br/>
să ne fiți alături de noi la celebrarea căsătoriei noastre, <br/>
care va avea loc pe data de   <br/>
</h5>
<h4>15 Octombrie  2022 la ora 18:00,</h4>
<h4>Restaurantul ”La Boero”,</h4>
<h5>Via del Guado, 20832 Desio MB, Italia</h5>
<br/>
<h5>Vă așteptăm să împărtășim bucuria acestor clipe unice!</h5>


                        </div>


                        <h4 class="card-footer">Vă așteptăm cu drag!</h4>

                        {data.introData.map((item) => { 
        return(
          
          
           <div class="card text-center container" style={{ maxWidth: "55rem" }}>
          <div class="" >
              <b>
               Vă rugăm să confirmați prezența <br /> până la data de {item.data_confirmare}    
              </b>
           
          </div>
       
<br/>
        

          <div class="mb-3">
            Abia așteptăm să vă vedem! Dacă aveți orice altă întrebare, <br/> vă rugăm să ne contactați sau
            scrieți-ne un mesaj:<br />
            
            {item.mire} & {item.mireasa} {item.tel} : <br/><a class=" m-2 btn_logo" href={item.phone}> <PhoneIphoneIcon /> Phone</a>
            <a className="m-2 btn_logo" href={item.viber}> <PhoneIcon />Viber</a>
           <a className="m-2  btn_logo" href={item.whatsapp}><WhatsApp />WhatsApp</a>
            
          </div>
        </div> )
      })}

                     {/*<a href="#forms" className="btn btn-primary">Confirmați Prezența</a> */}   

                    </div>




                </div>




            </div>


        </section>

    )
}

export default CardInvitatie;