import restaurant from "./images/restaurant.jpg";
import img_card from "./images/n.jpeg";
import imgOmSuflet from "./images/h8.jpg";
import imgheader from "./images/h2.jpg";
import imgheadermiini from "./images/hw.png";
import imgheadermiinimb from "./images/hw1.png";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        mire: "Grigore",
        mireasa: "Diana",
        title: "Invitatie Online Grigore si Diana",
        data: "15 Octombrie 2022",
        data_confirmare: "25 septembrie 2022",
        savedata: "~ Ne Căsătorim !!! ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "anusic007@gmail.com", 
       tel: "+39 388 956 4008",
       phone: "tel:+393889654008",
       viber: "viber://chat?number=%2B393889564008",
       whatsapp: "https://wa.me/+393889564008",
       messenger: "https://www.messenger.com/t/InvitatiiWeb",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "La Boero",
            data: "15 octombrie 2022, sâmbătă, ora 18:00",
            adress: "Via del Guado, 20832 Desio MB, Italia",
            harta: "https://g.page/la-boero?share",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11164.40293850001!2d9.2216873!3d45.6086196!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf05d3b173b2bc4fa!2sLa%20Boero!5e0!3m2!1sro!2s!4v1652891707977!5m2!1sro!2s",
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Vasile & Diana",
            parintii_nume2: "Ghenadie & Oxana",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Vasile & Svetlana",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "\"Căsnicia este o artă ce trebuie să o creezi în fiecare zi\"",
            message: " – R. Tagore",
        }
    ],

}

export default data;